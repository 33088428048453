import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';

import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import CustomLoading from '../../components/Shared/Loading/CustomLoading';
import Checkout_success from '../../components/Cart/AfterCheckoutCards/Checkout_success';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { get_orders } from '../../store/feature/orderSlice';
import { orderSelector } from '../../store/feature/orderSlice';
import Checkout_failure from '../../components/Cart/AfterCheckoutCards/Checkout_failure';

const CheckoutSuccessPageLayout = () => {
  const router = useRouter();
  const order_reference = router?.query?.order_reference;
  const order_status = router?.query?.status;
  const payment_method_id = router?.query?.payment_method_id;

  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  const { auth_key } = useSelector(authSelector);

  const dispatch = useDispatch();

  console.log(payment_method_id);

  useEffect(() => {
    if (auth_key) {
      if (order_status == 'success') {
        dispatch(
          get_orders({
            authKey: auth_key,
            bodyParam: {
              page: 1,
              per_page: 30,
              order_reference: order_reference,
            },
          })
        );
      }
    }
  }, [auth_key, order_reference, order_status]);

  const { orders, total_records, page, isFetching } =
    useSelector(orderSelector);

  //
  const closePopUP = () => {
    setShowError(false);
    setError_message('');
  };
  return (
    <>
      {isFetching && <CustomLoading />}
      {showError && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showError && (setShowError(false), setError_message(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp message={error_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {order_status === 'success' ? (
        <Checkout_success orders={orders} />
      ) : (
        <Checkout_failure
          order_reference={order_reference}
          auth_key={auth_key}
          payment_method_id={payment_method_id}
        />
      )}
    </>
  );
};

export default CheckoutSuccessPageLayout;
