/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import Cookies from 'js-cookie';
import { getThumbnailImage } from '../Constant/Constant';
import ReadingComposer from '../Composer/ReadingComposer';

const LayerCard2 = ({ item, path }) => {
  const router = useRouter();
  const { login, user_details } = useSelector(authSelector);

  const [isAccount, setIsAccount] = useState(
    Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`)
  );
  const [accountId, setAccountID] = useState(
    Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)
  );

  return (
    <div className="relative col-span-full lg:col-span-6 xl:col-span-4 bg-white shadow-md  border border-gray-200   rounded-card">
      <Link href={`${path}/${item.slug}`}>
        <a className="">
          {/* Card content */}

          <div className="flex flex-col gap-4 h-full ">
            {item?.cover_image_path && (
              <div className="w-full h-[180px] rounded-image   relative  overflow-hidden">
                <img
                  className=" h-full w-full  object-cover"
                  src={getThumbnailImage(item?.cover_image_path)}
                  alt="image"
                />
              </div>
            )}
            <div className="flex-grow p-5 ">
              {/* <Image src={default_image} layout="fill" objectFit="cover" /> */}
              {/* <Link href={`/integrations/${item.slug}`}> */}

              <h3 className="text-lg text-gray-800 font-semibold  ">
                {item.title}
              </h3>
              {/* </Link> */}
              <div className="">
                <ReadingComposer
                  contents={
                    item?.content.length > 100
                      ? item?.cover_image_path
                        ? item?.content?.substring(0, 100) + '...'
                        : item?.content?.substring(0, 280) + '...'
                      : item?.content
                  }
                  mainClass={`prose prose-sm sm:prose focus:outline-none   prose-a:text-primary  prose-a:border-0 hover:prose-a:underline prose-img:m-0`}
                />
              </div>
            </div>
          </div>
        </a>
      </Link>
      {login && isAccount && item?.account?.id == accountId && (
        <div
          className=" h-[40px] w-[40px]  absolute ltr:right-0 rtl:left-0 top-0 cursor-pointer  [z-100] justify-center items-center  bg-white ltr:rounded-bl-md rt:rounded-br-md"
          onClick={() => {
            router.push({
              pathname: '/c/edit_post',
              query: { account_id: accountId, article_slug: item.slug },
            });
          }}
        >
          <span className=" w-full h-full flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-7 w-7  mx-auto my-auto text-primary "
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
          </span>
        </div>
      )}
    </div>
  );
};

export default LayerCard2;
