import React, { memo } from 'react';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../constant/default_styles/HomeDefaultStyles';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import CustomLink from '../../constant/CustomLisnk';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const ListingCities = ({
  extra_data,
  styles,
  dynamic_text_value,
  check_by,
  listingsCities,
}) => {
  return (
    <div className="   " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          HomeCardBoxDefaultStyles
        )}
      >
        {extra_data?.title && (
          <h1 className="  " style={get_dynamic_styles(styles?.text_styles)}>
            {dynamic_text_convert({
              main_text: get_data_by_current_language({
                data_list: extra_data?.translated_data,
                key_name: 'extra_data?.title',
                default_data: extra_data?.title,
              }),
              value: dynamic_text_value ? dynamic_text_value : '',
              check_by: check_by ? check_by : '',
            })}
          </h1>
        )}

        <div
          className="flex"
          style={get_dynamic_styles(styles?.cities_box_styles)}
        >
          {listingsCities?.values?.map((item) => {
            return (
              <CustomLink
                href={`/l/c/${item}`}
                style={get_dynamic_styles_with_default_value(
                  styles?.button_styles,
                  HomeCardBoxDefaultStyles
                )}
              >
                {item}
              </CustomLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(ListingCities);
