/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';

import { listingLike } from '../../../store/feature/listingSlice';

import { check_login } from '../../../constant/check_auth';
import { Home_page_text } from '../../../constant/Translation/Home';
import ListingsCarouselSkeleton from '../../Shared/skeletonUI/Listing/ListingsCarouselSkeleton';
import {
  homePageSectionOptimisticUpdate,
  homePageSelector,
  setError,
} from '../../../store/feature/Pages/Home/homePageSlice';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import DynamicListingCard from '../../Shared/Cards/DynamicListingCard';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../../constant/default_styles/HomeDefaultStyles';
import ListingsCarousel from '../../Listings/ListingsCarousel';
import ListingsList from '../../Listings/ListingsList';
import ListingDynamicSkeleton from '../../Shared/skeletonUI/Listing/ListingDynamicSkeleton';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { get_data_by_current_language } from '../../../utils/GetDataByLanguage';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const LatestListings6 = ({
  product_collection,
  section_details,
  styles,
  extra_data,
}) => {
  const { home_page_sections } = useSelector(homePageSelector);

  // const { isSuccess } = useSelector(listingSelector);
  const dispatch = useDispatch();
  const router = useRouter();

  const like = (id, isLiked) => {
    if (check_login(router)) {
      wishDataOptimisticUpdate({ id, isLiked });
      dispatch(
        listingLike({
          id,
          isLiked,
        })
      )
        .then((res) => {
          if (!res.payload.code) {
            // dispatch(homeCollections({ authKey: auth_key }));
          } else {
            wishDataOptimisticUpdate({ id, isLiked: !isLiked });
            dispatch(setError([{ msg: res?.payload?.message }]));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // wish data optimistic  update
  const wishDataOptimisticUpdate = ({ isLiked, id }) => {
    dispatch(
      homePageSectionOptimisticUpdate(
        home_page_sections.map((section) => {
          if (section.order_number == section_details?.order_number) {
            return {
              ...section,
              data: [
                {
                  ...section.data[0],
                  listings: [
                    ...section.data[0].listings?.map((listing) => {
                      if (listing.id !== id) {
                        return listing;
                      } else {
                        return { ...listing, liked: !isLiked };
                      }
                    }),
                  ],
                },
              ],
            };
          } else {
            return section;
          }
        })
      )
    );
  };

  return (
    <div className=" " style={get_dynamic_styles(styles?.main_styles)}>
      {product_collection ? (
        <div
          style={get_dynamic_styles_with_default_value(
            styles?.box_styles,
            HomeCardBoxDefaultStyles
          )}
        >
          {/* header */}

          {extra_data?.is_show_header !== false && (
            <div className="flex justify-between items-center mb-4  ">
              <h2 className="heading-h2">
                {extra_data?.title
                  ? get_data_by_current_language({
                      data_list: extra_data?.translated_data,
                      key_name: 'extra_data?.title',
                      default_data: extra_data?.title,
                    })
                  : product_collection.title}
              </h2>
              <Link
                href={{
                  pathname: `/cp/${
                    product_collection.slug
                      ? product_collection.slug
                      : product_collection.id
                  }`,
                }}
                passHref
              >
                <a className=" block text-base text-primary font-semibold cursor-pointer">
                  {Home_page_text.home.view_all}
                </a>
              </Link>
            </div>
          )}

          {/*  */}
          <div className="    ">
            {extra_data?.listing_type === 'list' ? (
              <ListingsList
                Products={product_collection?.listings}
                like_listing={like}
              />
            ) : (
              <ListingsCarousel
                Products={product_collection?.listings}
                like_listing={like}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          style={get_dynamic_styles_with_default_value(
            styles?.box_styles,
            HomeCardBoxDefaultStyles
          )}
        >
          <ListingDynamicSkeleton
            listings_type={extra_data?.listing_type}
            listing_card_type={
              TYPE_CONSTANT.STYLES_CONFIGS?.listing_card?.card_type?.type
            }
          />
        </div>
      )}
    </div>
  );
};

export default memo(LatestListings6);
