import Link from 'next/link';
import WideSearchBox from '../../../components/SearchBox/WideSearchBox';
import WideSearchWithLocation from '../../../components/SearchBox/WideSearchWithLocation';
import AboutUs from '../../../components/StaticBlocks/AboutUs';
import CardBlock from '../../../components/StaticBlocks/CardBlock';
import CarouselCardBlock from '../../../components/StaticBlocks/CarouselCardBlock';
import CustomCta from '../../../components/StaticBlocks/CustomCta';
import DescriptionBlock from '../../../components/StaticBlocks/DescriptionBlock ';
import DividerBlock from '../../../components/StaticBlocks/DividerBlock';
import EmptyBlock from '../../../components/StaticBlocks/EmptyBlock';
import HeadingBlock from '../../../components/StaticBlocks/HeadingBlock';
import ImageBlock from '../../../components/StaticBlocks/ImageBlock';
import ImageDestinationBlock from '../../../components/StaticBlocks/ImageDestinationBlock';
import Paragraph from '../../../components/StaticBlocks/Paragraph';
import TitleDescriptionButton from '../../../components/StaticBlocks/TitleDescriptionButton';
import VideoBlock from '../../../components/StaticBlocks/VideoBlock';
import BlankPageBanner from '../../../components/home/Banner/BlankPageBanner';
import Latest_pages from '../../../components/home/BlogsWidget/Latest_pages';
import Categories6 from '../../../components/home/Categories/Categories6';
import GridButtonTypeCategories from '../../../components/home/Categories/GridButtonTypeCategories';
import GridTabTypeCategories from '../../../components/home/Categories/GridTabTypeCategories';
import ListCategories from '../../../components/home/Categories/ListCategories';
import FeaturedCard2 from '../../../components/home/FeaturedCrad/FeaturedCard2';
import LeftImageFeaturedCard from '../../../components/home/FeaturedCrad/LeftImageFeaturedCard';
import LatestListings6 from '../../../components/home/LatestListings/LatestListings6';
import LatestListings7 from '../../../components/home/LatestListings/LatestListings7';
import StoresForFollow3 from '../../../components/home/Stores/StoresForFollow3';
import StoresListView from '../../../components/home/Stores/StoresListView';
import QuoteBlock from '../QuoteBlock';
import TrustedBlock from '../TrustedBlock';
import BuilderHeaderCategories from '../../../components/Header/DynamicHeaderSecvtions/BuilderHeaderCategories';
import CategoriesDropDown from '../../../components/Header/DynamicHeaderSecvtions/CategoriesDropDown';
import DynamicSearchBox from '../../../components/SearchBox/DynamicSearchBox';
import HeaderMenuLink from '../../../components/StaticBlocks/HeaderMenuLink';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import Add_listing_button from '../../../components/Shared/Buttons/Add_listing_button';
import Notifications3 from '../../../components/NotificationsButton/Notifications3';
import Cart_button from '../../../components/HeaderButton/Cart_button';
import WishListButton from '../../../components/WishListButton/WishListButton';
import StoreButton from '../../../components/Header/StoreButton';
import DynamicHeaderProfile from '../../../components/HeaderProfileBox/DynamicHeaderProfile';
import Language_switch from '../../../components/HeaderButton/Language_switch_button';
import SignInButton from '../../../components/HeaderButton/SignInButton';
import Heading2Block from '../../../components/StaticBlocks/Heading2Block';
import { responsive_design_styles } from '../../../utils/ResponsiveDesign';
import { Menubar_text } from '../../../constant/Translation/Heading';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { getCookie } from 'cookies-next';
import FooterCategories from '../../../components/Footer/sections/FooterCategories';
import SocialMedia from '../../../components/Footer/sections/SocialMedia';
import ExternalLinks from '../../../components/Footer/sections/ExternalLinks';
import SingleLink from '../../../components/StaticBlocks/SingleLink';
import SitemapLink from '../../../components/Footer/sections/Sitemap';
import CardBlockImageFirst from '../../../components/StaticBlocks/CardBlockImageFirst';
import OPPSetup from '../../../components/Opp/OPPSetup';
import StripeSetup from '../../../components/Stripe/StripeSetup';
import DynamicListingFilter from '../../../components/Shared/DynamicListingFilter';
import AllListings from '../../../components/AllListings/AllListings';
import ListingsView from '../../../components/AllListings/ListingsView';
import ListingsInMap from '../../../components/AllListings/ListingsInMap';
import AllListingsWithMap from '../../../components/AllListings/AllListingsWithMap';
import { common_blocks_cases } from './CommonBlockCase';
import AddAmountINWallet2 from '../../../components/Wallet/AddAmount2';
import AnimatedTextBlock from '../../../components/StaticBlocks/AnimatedTextBlock';
import DynamicAllListings from '../../../components/AllListings/DynamicAllListings';
import MapByListingsAttribute from '../../../components/AllListings/MapByListingsAttribute';
import StoresListViewForDynamicPage from '../../../components/home/Stores/StoresListViewForDynamicPage';
import DynamicStoresListView from '../../../components/home/Stores/DynamicStoresListView';

//  component_decide
export const dynamic_page_component_decide = ({
  order_number,
  block,
  block_data_key,
  fetch_by,
  data,
  isStatic,
  extra_data,
  styles,
  dynamic_page_sections,
  AllListingFilter,
  setAllListingsFilter,
  isShowInMobile,
  isShowInWeb,
  isShowUnauthorizedGuest,
  isShowAuthorizedPeople,
  screen_width: width,
  isEnabled = true,
  AllAccountFilter,
  setAllAccountFilter,
}) => {
  const _styles = styles ?? data?.styles ?? {};
  const section = {
    order_number,
    block,
    block_data_key,
    fetch_by,
    data,
    isStatic,
    extra_data,
    styles,
    isEnabled,
  };

  // logo_styles
  const logo_styles = responsive_design_styles({
    width,
    parent_group: styles,
    child_group_name: 'logo_styles',
  });

  // check_unauthorized_guest
  const check_unauthorized_guest = (isShowUnauthorizedGuest) => {
    const isLoggedIN = getCookie(`${TYPE_CONSTANT.DOMAIN}_login`);

    return isShowUnauthorizedGuest ? true : isLoggedIN ? true : false;
  };

  // check_authorized_peop
  const check_authorized_people = (isShowAuthorizedPeople) => {
    const isLoggedIN = getCookie(`${TYPE_CONSTANT.DOMAIN}_login`);
    return isShowAuthorizedPeople ? true : isLoggedIN ? false : true;
  };

  switch (block) {
    case 'category':
      return (
        <Categories6
          categories={
            dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          extra_data={extra_data}
          styles={_styles}
          key={order_number}
        />
      );

    case 'account_collection':
      return (
        <StoresForFollow3
          account_collection={
            dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data[0]
              : null
          }
          section_details={section}
          styles={_styles}
          key={order_number}
        />
      );
    case 'listing_collection':
      return (
        <LatestListings6
          product_collection={
            dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data[0]
              : null
          }
          section_details={section}
          styles={_styles}
          key={order_number}
          extra_data={extra_data}
        />
      );

    case 'all_listings':
      return (
        <AllListings
          all_listings_data={
            dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          section_details={{
            ...section,
            data: dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null,
          }}
          styles={_styles}
          key={order_number}
          AllListingFilter={AllListingFilter}
        />
      );

    case 'all_accounts':
      return (
        <StoresListViewForDynamicPage
          all_accounts_data={
            dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          section_details={{
            ...section,
            data: dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null,
          }}
          styles={_styles}
          key={order_number}
          AllAccountFilter={AllAccountFilter}
          setAllAccountFilter={setAllAccountFilter}
        />
      );

    case 'layer_pages':
      return (
        <Latest_pages
          pages={
            dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          styles={_styles}
          key={order_number}
          extra_data={extra_data}
        />
      );

    case 'layer_blogs':
      return (
        <Latest_pages
          pages={
            dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          styles={_styles}
          key={order_number}
          extra_data={extra_data}
        />
      );

    case 'searchblock':
      return <WideSearchBox data={data} styles={_styles} key={order_number} />;
    case 'searchblock_with_location':
      return (
        <WideSearchWithLocation
          data={data}
          styles={_styles}
          key={order_number}
        />
      );
    case 'heading_title':
      return (
        data?.enabled && (
          <HeadingBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'description_block':
      return (
        data?.enabled && (
          <DescriptionBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'image_block':
      return (
        data?.enabled && (
          <ImageBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'image_with_destination_block':
      return (
        data?.enabled && (
          <ImageDestinationBlock
            data={data}
            styles={_styles}
            key={order_number}
          />
        )
      );
    case 'featured_card':
      return (
        data?.enabled && (
          <FeaturedCard2 data={data} styles={_styles} key={order_number} />
        )
      );
    case 'title_desc_button':
      return (
        data?.enabled && (
          <TitleDescriptionButton
            data={data}
            styles={_styles}
            key={order_number}
          />
        )
      );
    case 'promo_banner':
      return (
        data?.enabled && <BlankPageBanner styles={_styles} key={order_number} />
      );
    case 'video_featured':
      return (
        data?.enabled && (
          <VideoBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'quote_block':
      return (
        data?.enabled && (
          <QuoteBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'trusted_block':
      return (
        data?.enabled && (
          <TrustedBlock data={data} styles={_styles} key={order_number} />
        )
      );
    case 'divider_block':
      return <DividerBlock styles={_styles} key={order_number} />;
    case 'card_block':
      return <CardBlock data={data} styles={_styles} key={order_number} />;
    case 'card_block_top_image':
      return (
        <CardBlockImageFirst data={data} styles={_styles} key={order_number} />
      );
    case 'carousel_card_block':
      return (
        <CarouselCardBlock data={data} styles={_styles} key={order_number} />
      );
    case 'about_us':
      return <AboutUs data={data} styles={_styles} key={order_number} />;
    case 'left_image_featured_card':
      return (
        <LeftImageFeaturedCard
          data={data}
          styles={_styles}
          key={order_number}
        />
      );
    case 'list_categories':
      return (
        <ListCategories
          categories={
            dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          extra_data={extra_data}
          setAllListingsFilter={setAllListingsFilter}
          styles={_styles}
          key={order_number}
          AllListingFilter={AllListingFilter}
          AllAccountFilter={AllAccountFilter}
          setAllAccountFilter={setAllAccountFilter}
          fetch_by={fetch_by}
        />
      );
    case 'grid_categories':
      return (
        <GridButtonTypeCategories
          categories={
            dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          extra_data={extra_data}
          setAllListingsFilter={setAllListingsFilter}
          styles={_styles}
          key={order_number}
          AllListingFilter={AllListingFilter}
          AllAccountFilter={AllAccountFilter}
          setAllAccountFilter={setAllAccountFilter}
          fetch_by={fetch_by}
        />
      );
    case 'grid_tab_categories':
      return (
        <GridTabTypeCategories
          categories={
            dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          extra_data={extra_data}
          setAllListingsFilter={setAllListingsFilter}
          styles={_styles}
          key={order_number}
          AllListingFilter={AllListingFilter}
          AllAccountFilter={AllAccountFilter}
          setAllAccountFilter={setAllAccountFilter}
          fetch_by={fetch_by}
        />
      );
    case 'paragraph':
      return <Paragraph data={data} styles={styles} />;
    case 'cta':
      return <CustomCta data={data} styles={styles} />;
    case 'empty_block':
      return <EmptyBlock data={data} styles={styles} />;
    case 'animated_paragraph':
      return <AnimatedTextBlock data={data} styles={styles} />;

    case 'dynamic_listings_filter':
      return (
        <DynamicListingFilter
          data={data}
          styles={styles}
          setAllListingsFilter={setAllListingsFilter}
          AllListingFilter={AllListingFilter}
        />
      );
    case 'animated_paragraph':
      return <AnimatedTextBlock data={data} styles={styles} />;

    //  header blocks

    case 'logo':
      return (
        isEnabled &&
        ((isShowInMobile !== false && width < 768) ||
          (isShowInWeb !== false && width > 768)) && (
          <Link href="/" passHref={true}>
            <a className="  flex items-center   relative cursor-pointer  ">
              <img
                src={localStorage.getItem('logo')}
                style={{
                  // height: styles?.height ?? '50px',
                  // width: styles?.width ?? '',
                  maxHeight: logo_styles?.max_height ?? '44px',
                  width: logo_styles?.width,
                  objectFit: logo_styles?.object_fit ?? 'contain',
                }}
                alt="logo"
              />
            </a>
          </Link>
        )
      );

    case 'category':
      return (
        isEnabled &&
        ((isShowInMobile !== false && width < 768) ||
          (isShowInWeb !== false && width > 768)) && (
          <div className="flex-grow">
            <BuilderHeaderCategories />
          </div>
        )
      );
    case 'category_dropdown':
      return (
        isEnabled &&
        ((isShowInMobile !== false && width < 768) ||
          (isShowInWeb !== false && width > 768)) && (
          <div className="">
            <CategoriesDropDown />
          </div>
        )
      );
    case 'search':
      return (
        isEnabled &&
        ((isShowInMobile !== false && width < 768) ||
          (isShowInWeb !== false && width > 768)) && (
          <DynamicSearchBox styles={styles} />
        )
      );
    case 'handburger':
      return (
        isEnabled &&
        ((isShowInMobile !== false && width < 768) ||
          (isShowInWeb !== false && width > 768)) && (
          <button
            style={get_dynamic_styles(styles?.button_styles)}
            className="outline-none"
            // onClick={drawerOpen}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ltr:mr-[13px] rtl:ml-[13px] cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        )
      );
    case 'custom':
      return (
        isEnabled &&
        ((isShowInMobile !== false && width < 768) ||
          (isShowInWeb !== false && width > 768)) && (
          <HeaderMenuLink data={data} />
        )
      );
    case 'add_listing_btn':
      return (
        isEnabled &&
        check_unauthorized_guest(isShowUnauthorizedGuest) &&
        check_authorized_people(isShowAuthorizedPeople) &&
        ((width < 768 && isShowInMobile !== false) ||
          (width > 768 && isShowInWeb !== false)) && (
          <div className={isShowInMobile ? 'block' : ' hidden md:block  '}>
            <Add_listing_button
              title={Menubar_text.more.header_sell_btn_title}
            />
          </div>
        )
      );
    case 'notification_btn':
      return (
        isEnabled &&
        check_unauthorized_guest(isShowUnauthorizedGuest) &&
        check_authorized_people(isShowAuthorizedPeople) &&
        ((width < 768 && isShowInMobile !== false) ||
          (width > 768 && isShowInWeb !== false)) && (
          <div
            className={isShowInMobile ? 'block' : ' hidden md:block  '}
            id="header_language_switch_button"
          >
            <Notifications3 id_name="header_language_switch_button" />
          </div>
        )
      );
    case 'cart_btn':
      return (
        isEnabled &&
        check_unauthorized_guest(isShowUnauthorizedGuest) &&
        check_authorized_people(isShowAuthorizedPeople) &&
        ((width < 768 && isShowInMobile !== false) ||
          (width > 768 && isShowInWeb !== false)) && (
          <div className={isShowInMobile ? 'block' : ' hidden md:block  '}>
            <Cart_button />
          </div>
        )
      );
    case 'wishlist_btn':
      return (
        isEnabled &&
        check_unauthorized_guest(isShowUnauthorizedGuest) &&
        check_authorized_people(isShowAuthorizedPeople) &&
        ((width < 768 && isShowInMobile !== false) ||
          (width > 768 && isShowInWeb !== false)) && (
          <div
            className={isShowInMobile ? 'block' : ' hidden md:block  '}
            id="header_wish_list_button"
          >
            <WishListButton />
          </div>
        )
      );
    case 'store_btn':
      return (
        isEnabled &&
        check_unauthorized_guest(isShowUnauthorizedGuest) &&
        check_authorized_people(isShowAuthorizedPeople) &&
        ((width < 768 && isShowInMobile !== false) ||
          (width > 768 && isShowInWeb !== false)) &&
        TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 && (
          <div
            className={isShowInMobile ? 'block' : ' hidden md:block  '}
            id="header_store_button"
          >
            <StoreButton />
          </div>
        )
      );
    case 'profile_menubar':
      return (
        isEnabled &&
        check_unauthorized_guest(isShowUnauthorizedGuest) &&
        check_authorized_people(isShowAuthorizedPeople) &&
        ((width < 768 && isShowInMobile !== false) ||
          (width > 768 && isShowInWeb !== false)) && (
          <div
            className={isShowInMobile ? 'block' : ' hidden md:block  '}
            id="header_nav_items"
          >
            <DynamicHeaderProfile />
          </div>
        )
      );
    case 'language_switch_btn':
      return (
        isEnabled &&
        check_unauthorized_guest(isShowUnauthorizedGuest) &&
        check_authorized_people(isShowAuthorizedPeople) &&
        ((width < 768 && isShowInMobile !== false) ||
          (width > 768 && isShowInWeb !== false)) && (
          <div className={isShowInMobile ? 'block' : ' hidden md:block  '}>
            <Language_switch />
          </div>
        )
      );
    case 'sign_in_signup':
      return (
        isEnabled &&
        check_unauthorized_guest(isShowUnauthorizedGuest) &&
        check_authorized_people(isShowAuthorizedPeople) &&
        ((width < 768 && isShowInMobile !== false) ||
          (width > 768 && isShowInWeb !== false)) && (
          <div className={isShowInMobile ? 'block' : ' hidden md:block  '}>
            <SignInButton />
          </div>
        )
      );

    case 'heading2_block':
      return <Heading2Block data={data} styles={styles} />;

    // footer
    case 'footer_category':
      return isEnabled && <FooterCategories data={data} styles={styles} />;
    case 'social_links':
      return isEnabled && <SocialMedia data={data} styles={styles} />;
    case 'external_links':
      return isEnabled && <ExternalLinks data={data} styles={styles} />;
    case 'single_link':
      return isEnabled && <SingleLink data={data} styles={styles} />;
    case 'sitemap_links':
      return isEnabled && <SitemapLink data={data} styles={styles} />;

    // common block
    case 'opp_setup_button':
      return isEnabled && <OPPSetup data={data} styles={styles} />;
    case 'add_amount_in_wallet_button':
      return isEnabled && <AddAmountINWallet2 data={data} styles={styles} />;
    case 'stripe_setup_button':
      return isEnabled && <StripeSetup data={data} styles={styles} />;
    case 'listings_view_button':
      return isEnabled && <ListingsView styles={styles} />;

    case 'listings_map_view':
      return (
        isEnabled && (
          <ListingsInMap
            all_listings_data={
              dynamic_page_sections
                ? dynamic_page_sections.filter(
                    (item) => item.order_number === order_number
                  )[0]?.data
                : null
            }
            data={data}
            styles={styles}
            AllListingFilter={AllListingFilter}
            section_details={{
              ...section,
              data: dynamic_page_sections
                ? dynamic_page_sections.filter(
                    (item) => item.order_number === order_number
                  )[0]?.data
                : null,
            }}
          />
        )
      );
    case 'dynamic_all_listings':
      return isEnabled && <DynamicAllListings data={data} styles={styles} />;
    case 'all_listings_with_map_view':
      return (
        <AllListingsWithMap
          all_listings_data={
            dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null
          }
          section_details={{
            ...section,
            data: dynamic_page_sections
              ? dynamic_page_sections.filter(
                  (item) => item.order_number === order_number
                )[0]?.data
              : null,
          }}
          styles={_styles}
          key={order_number}
          AllListingFilter={AllListingFilter}
        />
      );
    case 'dynamic_all_listings_map_by_from_attribute':
      return (
        isEnabled && <MapByListingsAttribute data={data} styles={styles} />
      );

    default:
      return common_blocks_cases({ section });
  }
};
