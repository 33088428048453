import React, { useEffect, useState, memo } from 'react';
import { useRouter } from 'next/dist/client/router';

import { Menubar_text } from '../../constant/Translation/Heading';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import axios from 'axios';
import { search_icon } from '../Shared/Constant/Icons/AllIcons';
import LocationSearch from '../Shared/location/LocationSearch';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../constant/default_styles/HomeDefaultStyles';

const WideSearchWithLocation = ({ styles, data }) => {
  const [searchKey, setSearchKey] = useState(null);
  const [locationSearchKey, setLocationSearchKey] = useState(null);
  const [isFocus, setIsFocus] = useState(false);
  const router = useRouter();

  // location state
  const [cities, setCities] = useState(null);
  const [countries, setCountries] = useState(null);
  const [allLocationsItems, setAllLocationItems] = useState([]);

  useEffect(() => {
    // City

    const city_path =
      data.type === 'accounts' ? '/api/a/locations' : '/api/l/locations';
    axios.get(city_path, { params: { type: 'city', page: 1 } }).then((res) => {
      setCities(res.data.values);
      setLocationSearchKey(res.data.values[0]);
    });
    // Countries
    // axios
    //   .get('/api/l/locations', {
    //     params: { type: 'country', page: 1 },
    //   })
    //   .then((res) => {
    //     setCountries(res.data.values);
    //   });
  }, []);

  // set All locations
  useEffect(() => {
    if (cities?.length > 0) {
      setAllLocationItems([...cities]);
    }
  }, [cities]);
  //

  // handleKeypress
  const handleKeypress = (e) => {
    if (!searchKey?.replace(/\s/g, '').length <= 0) {
      if (e.key === 'Enter') {
        search_click();
      }
    }
  };

  // handelItemSelect
  const handelItemSelect = (item) => {
    setLocationSearchKey(item);
    search_click(item);
  };

  const search_click = (item = undefined) => {
    if (!searchKey?.replace(/\s/g, '').length <= 0) {
      router.push({
        pathname: `/search/${searchKey}`,
        query: {
          city: item ? item : locationSearchKey,
          type: data?.type ? data?.type : 'listings',
        },
      });
    }
  };

  useEffect(() => {
    if (router?.query?.search_key) {
      setSearchKey(router?.query?.search_key);
    }
  }, [0]);

  useEffect(() => {
    if (router?.query?.search_key) {
      setSearchKey(router?.query?.search_key);
    }
  }, [0]);

  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        className=" w-full flex items-center justify-center"
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          HomeCardBoxDefaultStyles
        )}
      >
        <div
          className=" h-auto    w-full mx-auto     rounded-2xl      flex  items-center    transition duration-200 "
          style={get_dynamic_styles(styles?.search_box_styles)}
        >
          <div className="flex-grow h-full flex flex-1 ">
            <div className="w-full h-full flex-grow ">
              <input
                value={searchKey}
                className="h-full w-full  bg-transparent px-4 placeholder-gray-400 outline-none border-none ring-0 focus:ring-0"
                type="text"
                placeholder={Menubar_text.more.search}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                onChange={(e) => setSearchKey(e.target.value)}
                onKeyPress={(e) => handleKeypress(e)}
              />
            </div>
            <hr className=" flex-none w-0.5 my-auto rounded-md h-3/4 bg-gray-200" />
            <div className="w-full h-full flex-grow ">
              <LocationSearch
                locationSearchKey={locationSearchKey}
                setLocationSearchKey={setLocationSearchKey}
                handleKeypress={handleKeypress}
                locations_list={allLocationsItems}
                place_holder={Menubar_text.more.search}
                handelItemSelect={handelItemSelect}
              />
            </div>
          </div>

          <div className=" flex-none h-full  min-w-[62px]   bg-primary   ltr:rounded-r-2xl rtl:rounded-l-2xl ">
            <button
              className={[
                'w-full h-full     cursor-pointer transition duration-1000  ease-in-out  flex items-center justify-center text-white  ',
              ].join(' ')}
              onClick={(e) => search_click()}
            >
              {search_icon}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(WideSearchWithLocation);
